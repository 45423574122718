import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const VisualBanner = memo(function VisualBanner({
  cta,
  image,
  subtitle,
  title,
}: Props) {
  if (!title || !subtitle) {
    return null
  }

  return (
    <Container>
      <Fade>
        <Wrapper>
          {image ? <Image {...image} /> : null}
          <Text>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            {cta ? <CTA {...cta} /> : null}
          </Text>
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 0 10vw 6rem;

  @media (max-width: 1199px) {
    padding-right: 8.454vw;
    padding-left: 8.454vw;
  }
`

const Wrapper = styled.div`
  padding-bottom: 37%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding: 3rem 1.875rem;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  bottom: 3.75rem;
  right: 3.75rem;
  left: 3.75rem;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 1.875rem;
    right: 1.875rem;
    left: 1.875rem;
  }

  @media (max-width: 767px) {
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
    text-align: center;
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.0563rem;
  line-height: 2.25rem;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 0.75rem;
`

const CTA = styled(Button)`
  margin-top: 1.5rem;
`
