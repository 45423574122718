import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'
import Fade from 'react-reveal/Fade'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  images?: string[]
  items: ItemProps[]
  title?: string
}

export const Accordions = memo(function Accordions({
  images,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container dial={4} row tag="section" wrap>
      <Fade bottom distance="3.75rem">
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          <Accordion allowZeroExpanded preExpanded={['first']}>
            {items.map((item, index) => (
              <Item
                key={index}
                isFirst={index === 0 ? true : false}
                {...item}
              />
            ))}
          </Accordion>
        </LeftSide>
        <RightSide>
          {images && images.length > 0 ? (
            <Images row wrap>
              {uniq(images).map((item, index) => (
                <Image
                  key={index}
                  style={{ backgroundImage: `url(${item})` }}
                />
              ))}
            </Images>
          ) : null}
        </RightSide>
      </Fade>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  overflow: hidden;
  padding: 0 10vw 12.5rem;
  position: relative;
  &:before {
    content: '';
    width: 35.556vw;
    height: 28.958vw;
    max-width: 512px;
    max-height: 417px;
    background: url('/wave.svg') no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    bottom: 3.75rem;
    right: 0;
  }
  > div {
    width: 50%;
  }

  @media (max-width: 1199px) {
    padding: 0 8.454vw 5.625rem;
  }

  @media (max-width: 1023px) {
    &:before {
      display: none;
    }
    > div {
      width: 100%;
      &:first-of-type {
        order: 2;
        margin-top: 3.25rem;
      }
    }
  }
`

const LeftSide = styled.div`
  padding-right: 4.167vw;

  @media (max-width: 1023px) {
    padding-right: 0;
  }
`

const Title = styled.div`
  max-width: 400px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    max-width: none;
    margin-bottom: 3rem;
  }
`

const RightSide = styled.div``

const Images = styled(FlexBox)`
  max-width: 466px;
  margin-left: auto;

  @media (max-width: 1023px) {
    max-width: 343px;
    margin-left: 0;
  }
`

const Image = styled.div`
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50%;
  &:nth-of-type(2n-3) {
    transform: translateY(3rem);
  }

  @media (max-width: 1023px) {
    &:nth-of-type(2n-3) {
      transform: translateY(1.25rem);
    }
  }
`
